export default {
  dateTime: "Date",
  date: "Date",
  terminal: "Terminal",
  terminalHint: "0001",
  store: "Store Name",
  storeName: "Store Name",
  storeCode: "Store Code",
  cashCount: "Amount",
  printCount: "Print Count",
  storAbbreviation: "Store Abbreviation",
  enterpriseAbbreviation: "Company Abbreviation",
  enterpriseCode: "Company Code",
  enterpriseName: "Company Name",
  enterprise: "Company",
  payDateTime: "Payment Date",
  payKind: "Payment Options ",
  payKindHint: "All Payment Options",
  paymentNo: "Payment No",
  outTradeNo: "Transaction No",
  deviceIdentificationNumber: "Terminal Identification Number",
  deviceProcessingNumber: "Terminal processing number",
  otherWalletNumber: "WalletTrade No",
  tradeKind: "Transaction Type",
  tradKindHint: "All",
  searchReset: "Reset",
  search: "Search",
  recordDownload: "Download Data",
  passage: "",
  payment: "Payment",
  paymentKind: "Payment Option(s)",
  kind: "Transaction Type",
  all: "All",
  refund: "Refund",
  refundDate: "Refund Date",
  refundNo: "Refund No",
  cashAll: "Full Amount",
  licenseNo: "License No",
  terminalCount: "No of Terminals",
  terminalNo: "Terminal Sequence",
  unknownDeviceModel: "",
  appVer: "App Version",
  device: "Terminal Type",
  lastLoginTime: "Last Sign Time",
  status: "Status",
  login: "Login",
  logout: "Logout",
  userId: "User ID",
  userName: "Username",
  userIdInputHint: "Please enter your user Id",
  password: "Password",
  passwordInputHint: "Please enter your password",
  forgotPassword: "Forgot Password? ",
  name: "Name",
  authority: "Role",
  userAuthority: "Role",
  address: "Email Address",
  delete: "Delete",
  disable: "Disable",
  trade: "Transaction",
  orderManage: "Order Manage",
  paymentManage: "Payment Manage",
  tradePoint: "Point Manage",
  dashboard: "Dashboard",
  receipt: "Deposit",
  setting: "Settings",
  supportCenter: "Help",
  detail: "Details",
  bankCode: "Bank Code",
  bankName: "Name of Financial Institution",
  bankBranchName: "Branch Name",
  bankBranchCode: "Branch Code",
  bankNo: "Account Number",
  bankNoType: "Account Holder",
  bankKind: "Account Type",
  hankakukana: "Half-width Kana",
  paymentDetail: "Deposit Details",
  paymentShopDetail: "Usage Amount by store",
  payDate: "Payment Date",
  paymentDetailMore: "You may download the detailed payment statement in an Excel format.",
  paymentDetailMoreDownload: "Download the payment details",
  dataDownload: "Download Data Below",
  brand: "Payment Option",
  notification: "Notifications",
  noNotification: "No Notification",
  importNotification: "Important Notifications",
  otherNotification: "Notifications",
  brandTypeRatio: "Payment Option Type Percentage",
  ratio: "Percentage",
  currentDay: "Today ",
  currentWeek: "Week",
  currentMonth: "Month",
  today: "Today",
  yestoday: "Yesterday",
  lastWeek: "Last Week",
  lastMonth: "Last Month",
  quarter: "Quarter",
  year: "Year",
  comparedTheSameDayLastMonth: "Month on Month Comparison",
  comparedTheSameWeekLastMonth: "Month on Month Comparison",
  comparedLastMonth: "Month on Month Comparison",
  comparedTheSameMonthLastMonth: "Compared Previous Month",
  comparedTheSameQuarterLastYear: "Compared Last Year",
  comparedLastYear: "Compared Last Year",
  comparedAmountToday: "Amount on the same day of last month",
  comparedAmountYestoday: "Amount on the same day of last month",
  comparedAmountCurrentWeek: "Amount on the same week of last month",
  comparedAmountCurrentMonth: "Amount of last month",
  comparedAmountLastWeek: "Amount of last month",
  comparedAmountLastMonth: "Amount of two months ago",
  comparedAmountQuarter: "Last year's quarterly amount",
  comparedLastYearYear: "Amount of last year",
  startDate: "Start Date",
  endDate: "End Date",
  moneyUnit: "₮",
  money: "Amount",
  count: "Count",
  countUnit: "",
  editPassword: "Change Password",
  newPassword: "New Password",
  oldPassword: "Current Password",
  rePassword: "Confirm password",
  pwdDiffMsg: "The two passwords entered do not match",
  savePassword: "Confirm",
  currentPassword: "Current Password",
  save: "Save",
  effective: "Valid",
  uneffective: "Invalid",
  email: "Email",
  phoneNum: "Phone",
  cancel: "Cancel",
  submit: "Confirm",
  success: "Success",
  fail: "Fail",
  confirm: "Confirm",
  on: "On",
  off: "Off",
  more: "More",
  toggleMenu: "Toggle Menu",
  onlineHelp: "Online Help",
  remark: "Remarks",
  editRemark: "Remarks editing",
  tips: {
    dataSearchDelay:
      "*Please note that the real-time payment might take a little while to be reflected on this dashboard."
  },
  twoStepAuth: "2-Step verification",
  twoStepEmail: "Email address to receive authentication code",
  confirmTwoStepEmail: "Re-confirm the email address",
  configuredTwoStepEmail: "Current email address",
  twoStepSetSuccess: "The two-factor authentication has been completed.",
  resetMFA: "Confirm email modification",
  rules: {
    requireEmail: "Please enter your email address.",
    errorEmail: "Please enter a valid email address.",
    errorConfirmEmail: "Please enter the same email address."
  },
  //数据罗盘
  dataCompassRouter: "Data compass",
  dataScreenRouter: "Data panel",
  tradeDailyRouter: "Daily analysis",
  tradeMonthlyRouter: "Monthly analysis",
  fontSize: "Font Size",
  large: "L",
  medium: "M",
  small: "S",
  operation: "More details"
};
