<template>
  <div id="app" :class="'newStyles ' + fontSize + ' ' + $settings.style" :lang="$store.state.app.language">
    <router-view />
    <div class="chatbotAvatar" v-if="showChatBot" :class="hideChatBot ? 'hide' : ''">
      <img src="./assets/newStyle/chatbot.png" alt="" @click="$window.cbshow()" />
      <div class="hideChatbot" @click="hideChatBot = !hideChatBot"></div>
    </div>
  </div>
</template>
<script>
import { getSessionStorage, getLocalStorage } from "@/utils/storage";
export default {
  data() {
    return {
      hideChatBot: false,
      showChatBot: false,
      fontSize: "small"
    };
  },
  mounted() {
    //给全局设定 lang
    document.documentElement.lang = this.$store.state.app.language;

    if (getLocalStorage("fontSize")) {
      this.fontSize = getLocalStorage("fontSize");
    }
    if (this.$settings.gTag) {
      let script1 = document.createElement("script");
      script1.async = true;
      script1.src = `https://www.googletagmanager.com/gtag/js?id=${this.$settings.gTag}`;
      document.head.appendChild(script1);

      let script2 = document.createElement("script");
      script2.textContent = `
        window.dataLayer = window.dataLayer || [];
        function gtag() {
          dataLayer.push(arguments);
        }
        gtag("js", new Date());
        gtag("config", "${this.$settings.gTag}");

        gtag('event', 'user_login', {
          'ent_name': "${this.$store.state.app.userInfo.entName}",
          'ent_displayname':"${this.$store.state.app.userInfo.entDisplayName}",
          'user_id':"${this.$store.state.app.userInfo.userId}",
          'uuid':"${this.$store.state.app.userInfo.userId}"
        });
      `;
      document.head.appendChild(script2);
    }
    if (this.$settings.chatBot) {
      this.showChatBot = true;
    }
  },
  computed: {
    baseColor() {
      const element = document.querySelector(".newStyles");
      if (element) {
        return getComputedStyle(element)
          .getPropertyValue("--baseColor")
          .trim();
      }
      return null; // 或者返回一个默认值
    }
  }
};

//该注释代码不要删除
// import { associateFcm, unassociateFcm } from "api/user";
// import { getSessionStorage } from "@/utils/storage";
// export default {
//   created() {
//     let messaging = this.$messaging;
//     messaging
//       .requestPermission()
//       .then(() => {
//         console.log("Notification permission granted.");
//         messaging
//           .getToken({
//             vapidKey: "BH_th3WtOVPGm4_qABeD4jtO9VFWox00E6F-hVscvhO0NQpJmvAe8UjPMcQfR1Fk26abGzN0Ml4mLWSCjV4VCFY"
//           })
//           .then(currentToken => {
//             if (currentToken) {
//               let oldFcmToken = window.localStorage.getItem("oldFcmToken");
//               if (oldFcmToken) {
//                 console.log("FcmToken存在:" + oldFcmToken);
//                 console.log("当前FCM返回token:" + currentToken);
//                 if (oldFcmToken != currentToken) {
//                   console.log("FcmToken变动，刷新FcmToken");
//                   window.localStorage.setItem("isNewFcmToken", "1");
//                   window.localStorage.setItem("fcmToken", currentToken);
//                 }
//               } else {
//                 console.log("FcmToken不存在，保存token");
//                 window.localStorage.setItem("isNewFcmToken", "1");
//                 window.localStorage.setItem("fcmToken", currentToken);
//                 window.localStorage.setItem("oldFcmToken", currentToken);
//               }
//             } else {
//               console.log("No registration token available. Request permission to generate one.");
//             }
//           })
//           .catch(err => {
//             console.log("An error occurred while retrieving token. ", err);
//           });
//       })
//       .catch(err => {
//         console.log("Unable to get permission to notify.", err);
//       });
//     messaging.onMessage(function(payload) {
//       console.log("Message received. ", payload);
//     });
//   }
// };
</script>
