<template>
  <el-date-picker
    v-model="dateRange"
    :default-value="data"
    :type="format == 'yyyy/MM/dd HH:mm:ss' ? 'datetimerange' : 'daterange'"
    range-separator="-"
    :start-placeholder="$t('startDate')"
    :editable="false"
    :clearable="false"
    :format="format"
    :value-format="format"
    :end-placeholder="$t('endDate')"
    :picker-options="pickerOptions"
    :default-time="['00:00:00', '23:59:59']"
  >
  </el-date-picker>
</template>

<script>
import i18n from "@/lang";
export default {
  name: "SearchDate",
  props: {
    data: {
      type: Array
    },

    pickerOptions: {
      type: Object,
      default: function() {
        return {
          shortcuts: [
            {
              text: i18n.t("today"),
              onClick(picker) {
                const begin = picker
                  .moment(new Date())
                  .format(picker.format == "yyyy/MM/dd" ? "yyyy/MM/DD" : "yyyy/MM/DD 00:00:00");
                const end = picker
                  .moment(new Date())
                  .format(picker.format == "yyyy/MM/dd" ? "yyyy/MM/DD" : "yyyy/MM/DD 23:59:59");
                picker.$emit("pick", [begin, end]);
              }
            },
            {
              text: i18n.t("yestoday"),
              onClick(picker) {
                const begin = picker
                  .moment(new Date())
                  .add(-1, "days")
                  .format(picker.format == "yyyy/MM/dd" ? "yyyy/MM/DD" : "yyyy/MM/DD 00:00:00");
                const end = picker
                  .moment(new Date())
                  .add(-1, "days")
                  .format(picker.format == "yyyy/MM/dd" ? "yyyy/MM/DD" : "yyyy/MM/DD 23:59:59");
                picker.$emit("pick", [begin, end]);
              }
            },
            {
              text: i18n.t("currentWeek"),
              onClick(picker) {
                const begin = picker
                  .moment()
                  .startOf("isoWeek")
                  .format(picker.format == "yyyy/MM/dd" ? "yyyy/MM/DD" : "yyyy/MM/DD 00:00:00");
                const end = picker
                  .moment()
                  .endOf("isoWeek")
                  .format(picker.format == "yyyy/MM/dd" ? "yyyy/MM/DD" : "yyyy/MM/DD 23:59:59");

                picker.$emit("pick", [begin, end]);
              }
            },
            {
              text: i18n.t("lastWeek"),
              onClick(picker) {
                const begin = picker
                  .moment()
                  .startOf("isoWeek")
                  .add(-1, "weeks")
                  .format(picker.format == "yyyy/MM/dd" ? "yyyy/MM/DD" : "yyyy/MM/DD 00:00:00");
                const end = picker
                  .moment()
                  .endOf("isoWeek")
                  .add(-1, "weeks")
                  .format(picker.format == "yyyy/MM/dd" ? "yyyy/MM/DD" : "yyyy/MM/DD 23:59:59");
                picker.$emit("pick", [begin, end]);
              }
            },
            {
              text: i18n.t("currentMonth"),
              onClick(picker) {
                const begin = picker
                  .moment()
                  .startOf("month")
                  .format(picker.format == "yyyy/MM/dd" ? "yyyy/MM/DD" : "yyyy/MM/DD 00:00:00");
                const end = picker
                  .moment()
                  .endOf("month")
                  .format(picker.format == "yyyy/MM/dd" ? "yyyy/MM/DD" : "yyyy/MM/DD 23:59:59");
                picker.$emit("pick", [begin, end]);
              }
            },
            {
              text: i18n.t("lastMonth"),
              onClick(picker) {
                const begin = picker
                  .moment()
                  .month(picker.moment().month() - 1)
                  .startOf("month")
                  .format(picker.format == "yyyy/MM/dd" ? "yyyy/MM/DD" : "yyyy/MM/DD 00:00:00");
                const end = picker
                  .moment()
                  .month(picker.moment().month() - 1)
                  .endOf("month")
                  .format(picker.format == "yyyy/MM/dd" ? "yyyy/MM/DD" : "yyyy/MM/DD 23:59:59");
                picker.$emit("pick", [begin, end]);
              }
            }
          ]
          // disabledDate: time => {
          //   return time.getTime() > moment().endOf("days");
          // }
        };
      }
    },

    format: {
      type: String,
      default: "yyyy/MM/dd HH:mm:ss"
    }
  },
  mounted() {
    this.$forceUpdate();
  },
  computed: {
    dateRange: {
      get() {
        return this.data;
      },

      set(val) {
        this.$emit("update:data", val);
      }
    }
  }
};
</script>

<style>
/* .el-icon-app-date {
  width: 24px;
  height: 24px;
  background: url(./../assets/date_logo.png) center no-repeat;
  background-size: cover;
  margin: 10px !important;
} */
/* .el-range-editor.el-input__inner {
  padding: 0;
} */
/* .el-range-input:nth-of-type(1) {
  border-left: 1px solid rgba(0, 0, 0, 0.12);
  padding-left: 20px;
} */
/* .el-range-input {
  width: 140px !important;
} */
.el-picker-panel__shortcut {
  color: #333;
  font-weight: 600;
}
.el-picker-panel__content th {
  color: #333 !important;
  font-weight: 600;
}
</style>
